<template>
	<div class="baseBody">
		<ScaleBox>
			<div class="main-wraper relative">
        <div id="container" style="width: 100%;height: 100%;position: absolute;z-index: 100;left: 0px;top: 100px;">
        </div>
				<div class="dateWeek"><DynamicDate></DynamicDate></div>
				<div class="fullBox"><ScreenfullBox></ScreenfullBox></div>
				<div class="baseTitleContent">
					<div class="baseTitleMid">
						<div class="top">
							<div class="topLine">
								<div class="title text-center base-font-60 text-white text-bold">智慧安保数据看板</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex align-center justify-between">
					<div>
						<div class="baseTitleLeft"></div>
					</div>
					<div>
						<div class="baseTitleRight"></div>
					</div>
				</div>
        <div class="baseCallAlarm " @click="dialogVisible = true">
            <div class="flex align-center">
              <div class="bjImg"><el-image :src="bjImg"></el-image></div>
              <div class="title">一键报警</div>
            </div>
        </div>
        
        <div class="basePoiBox">
          <div class="relative">
            <div class="poiIcon">
                      <img class="el-image" :src="mapIcon" alt="">
                  </div>
                  <div class="poi-content">
                    <div>
                      <input type="text" v-model="keyword" id="pickerInput" placeholder="搜索位置、公交站、地铁站" maxlength="256" autocomplete="off" class="active" data-spm-anchor-id="">
                      <div id="searchResults" ></div>
                    </div>
                  </div>
            <i class="el-icon-search"></i>
          </div>
        </div>

        <el-dialog
          :visible.sync="dialogVisible"
          append-to-body
          center
          custom-class="dialogCustom"
          :show-close="false"
          width="30%"
          :before-close="handleClose">
         <div class="text-white" >
          <div class="flex aling-cneter justify-between">
            <div class="base-font-17">呼叫内容</div>
            <i class="el-icon-circle-close base-font-20" @click="dialogVisible = false"></i>
          </div>
          <div class="dialogBox">
          <el-form ref="form" :model="form" label-width="80px">
      
          <el-form-item label="呼叫范围">
            <el-select v-model="form.range" placeholder="请选择活动区域" style="width:100%;">
              <el-option :label="item.name" :value="item.value" v-for="(item,index) in rangeList" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="呼叫人员">
            <el-input type="textarea" v-model="form.hjPeo"></el-input>
          </el-form-item>
          <el-form-item label="呼叫内容">
            <el-input type="textarea" v-model="form.hjnr"></el-input>
          </el-form-item>
          </el-form>
        </div>
         </div>
          <span slot="footer" class="dialog-footer">
            <div class="flex align-center justify-center">
              <div class="dialogBtn qx" @click="dialogVisible = false">取消呼叫</div>
              <div class="dialogBtn qd" @click="callBtn">确定呼叫</div>
            </div>
            <!-- <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
          </span>
        </el-dialog>

			</div>
		</ScaleBox>
	</div>
</template>

<script>

  import AMapLoader from "@amap/amap-jsapi-loader";
  import DynamicDate from "../components/dynamicDate/index.vue";
  import ScreenfullBox from "../components/screenfull/index.vue";
  import ScaleBox from "../components/sacleBox/index.vue"
  import bjImg from "@/assets/images/tel.png";
  import mapIcon from "@/assets/images/mapIcon.png";

  import {centerData} from "@/api/api";
  export default {
    components: {
      ScaleBox,
      DynamicDate,
      ScreenfullBox
    },
		data() {
			return {
        map:'',
        lineList:[],
        postionList:[],
        runMakerList:[],
        bjImg:bjImg,
        mapIcon:mapIcon,
        keyword:'',
        dialogVisible:false,
        form:{
          hjPeo:'',
          hjnr:'',
          range:'01'
        },
        rangeList:[
          {name:"1公里",value:'01'},
          {name:"2公里",value:'02'},
          {name:"3公里",value:'03'},
          {name:"4公里",value:'04'}
        ]

			}
		},
    mounted() {

      this.changeData();
    },
		methods:{
      changeData(){
        let _self=this
        centerData().then(res => {
          _self.lineList=res.data;
          _self.$nextTick(function() {
            _self.initAMap();
          });
        });
      },
      initAMap() {
        AMapLoader.load({
          key: '18997a286e73c2d94837b10f89516387', // 高德地图API Key
          version: '2.0', // 指定API版本
          plugins: ["AMap.ControlBar","AMap.ToolBar","AMapUI","AMapUI.PoiPicker",'AMap.PlaceSearch','AMap.AutoComplete'], // 需要使用的插件
          // AMapUI: {
          //   version: '1.1',
          //   "plugins": ["poi-picker"]
          // }
          AMapUI: {
            version: '1.1',
            plugins:['overlay/SimpleMarker']
        }
        //   "AMapUI": {
        //   "version": "1.1",
        //   "plugins": ["poi-picker"]
        // }
        }).then((AMap) => {
          let longitude='116.333926';
          let latitude='39.997245';
          this.postionList=[];
          this.lineList.forEach((obj)=>{
            obj.pointList.forEach((pointObj)=>{
              this.postionList.push(pointObj);
            })
          })
          if(this.postionList.length>0){
            longitude=this.postionList[0].longitude;
            latitude=this.postionList[0].latitude;
          }
          console.log("longitude:"+longitude+";latitude"+latitude);
          this.map = new AMap.Map('container', {
            rotateEnable:true,
            pitchEnable:true,
            zoom: 17,
            rotation: -15,
            viewMode:'3D', //开启3D视图,默认为关闭
            zooms:[2,20],
            center:[longitude,latitude]
          });
          this.map.setMapStyle('amap://styles/darkblue');
          // var controlBar = new AMap.ControlBar({
          //   position:{
          //     right:'10px',
          //     top:'10px'
          //   }
          // });
          // controlBar.addTo(this.map);
          //
          // var toolBar = new AMap.ToolBar({
          //   position:{
          //     right:'40px',
          //     top:'110px'
          //   }
          // });
          // toolBar.addTo(this.map);
          // this.setPostion(AMap);
          // this.setLinerList(AMap);
          // this.runMaker(AMap);
           //输入提示
          var autoOptions = {
              input: "pickerInput",
              type: "", //数据类别
              pageSize: 10, //每页结果数,默认10
              pageIndex: 1, //请求页码，默认1
              extensions: "base" //返回信息详略，默认为base（基本信息）
          };
          var auto = new AMap.AutoComplete(autoOptions);
          var placeSearch = new AMap.PlaceSearch({
              map: this.map
          });  //构造地点查询类
          auto.on("select", select);//注册监听，当选中某条记录时会触发
          placeSearch.search(this.keyword, function(status, result) {
          // 搜索成功时，result即是对应的匹配数据
          console.log(result,'result')
          })
          AMap.event.addListener(placeSearch, "complete", function(e,result){
              console.log(e,'3333333333333')
              console.log(result,'result')
          }); //返回结果


           // 执行POI搜索
            AMap.event.addListener(placeSearch, 'complete', function(result) {
              // 搜索完成时的回调函数
              console.log(result.poiList,'44444');
            });
          function select(e) {
            console.log(e,'eeeee')
              placeSearch.setCity(e.poi.adcode);
              placeSearch.search(e.poi.name);  //关键字查询查询
          }



          // AMapUI.loadUI(['misc/PoiPicker'], function(PoiPicker) {

          //   var poiPicker = new PoiPicker({
          //       //city:'北京',
          //       input: 'searchipt'
          //   });
          //   poiPicker.open();
          //   //初始化poiPicker
          //   // poiPickerReady(poiPicker);
          //   });

            // AMapUI.setDomLibrary($);

//加载PoiPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
          // AMapUI.loadUI(['misc/PoiPicker'], function(PoiPicker) {
          //   var poiPicker = new PoiPicker({
          //       input: 'searchipt' //输入框id
          //   });
          //   console.log(PoiPicker,'PoiPicker')
          //   //监听poi选中信息
          //   poiPicker.on('poiPicked', function(poiResult) {
          //     //用户选中的poi点信息
          //     console.log(poiResult,'poiResult')
          //   });
          // });
          // AMapUI.loadUI(['misc/PoiPicker','lib/$'], function(PoiPicker) {

          //   var poiPicker = new PoiPicker({
          //         input: 'pickerInput', //输入框id
          //         city:'芜湖'
          //     });
          //     //监听poi选中信息
          //     poiPicker.on('poiPicked', function(poiResult) {
          //       console.log(poiResult,'poiResult')
          //       //用户选中的poi点信息
          //     });
          // });

      

//加载PoiPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分

          // 初始化POI选点器
          // const poiPicker = new AMapUI.PoiPicker({
          //   input: 'searchipt' // 输入框的id
          // });
        //   var poiPicker = new AMapUI.PoiPicker({
        //     input: 'searchInput',
        //     placeSearchOptions: {
        //         map: this.map,
        //         pageSize: 10
        //     },
        //     searchResultsContainer: 'searchResults'
        // });
          // 打开POI选点器
        //  poiPicker.open();
          // const poiPicker = new AMapUI.PoiPicker({
          //     input: 'searchipt' // 输入框的id
          //   });

          // AMapUI.loadUI(['misc/PoiPicker'], function(PoiPicker) {

          // var poiPicker = new PoiPicker({
          //     //city:'北京',
          //     input: 'searchipt'
          // });

          // //初始化poiPicker
          // this.poiPickerReady(poiPicker,this.map,AMap);
          // });
          // var poiPicker =new AMapUI.PoiPicker({
          //    input: 'searchipt'
          // })

          // this.poiPickerReady(poiPicker,this.map,AMap);


        }).catch(e => {
          console.log(e);
        });
      },
      setPostion(AMap){
        let pointList=[];
        this.postionList.forEach((pointObj)=>{
          let title=pointObj.name;
          if(pointObj.recordDateTime){
            title+="<br/>到达时间:"+pointObj.recordDateTime;
          }
          title="<div style='padding: 10px;font-size: 22px;border-radius: 10px;background-color: #406390;color: #ffffff;line-height: 28px;'>"+title+"</div>"
          let point= new AMap.Marker({
            position: [pointObj.longitude, pointObj.latitude],
            icon: new AMap.Icon({
              // 图标尺寸
              size: new AMap.Size(46, 46),
              // 图标的取图地址
              image: 'https://gszngc.oss-cn-hangzhou.aliyuncs.com/diy/diyGrid/202406071056012024060710560104100057334.png',
              // 图标所用图片大小
              imageSize: new AMap.Size(46, 46),
            }),
            offset: new AMap.Pixel(0,0), //设置偏移量
            label: {
              direction: 'top',
              content: title,
              offset: new AMap.Pixel(0, -5),
            }
          });
          pointList.push(point);
          // 创建圆
          var circle = new AMap.Circle({
            center: [pointObj.longitude, pointObj.latitude],
            radius: pointObj.distance, //半径
            fillColor: '#8cc4fc', // 填充颜色
            fillOpacity: 0.2,
            borderWeight: 5,
            strokeColor: "#99999",
            zIndex: 50,
          })
          pointList.push(circle);
          // 缩放地图到合适的视野级别
          //this.map.setFitView([ circle ])
        })
        this.map.add(pointList);
      },
      setLinerList(AMap){
        this.lineList.forEach((obj)=>{
          this.setLiner(AMap,obj);
        })
      },
      setLiner(AMap,linerObj){
        if(linerObj.recordList!=null && linerObj.recordList.length>0){
          // 绘制轨迹
          let lineArr=[];
          linerObj.recordList.forEach((obj)=>{
            lineArr.push([obj.longitude, obj.latitude])
          })
          this.runMakerList.push({num:0,workerNames:linerObj.workerNames,recordList:linerObj.recordList,maker:null});
          // 绘制轨迹
          new AMap.Polyline({
            map: this.map,
            path: lineArr,
            showDir:true,
            strokeColor: linerObj.sceneDescription,  //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6,      //线宽
            // strokeStyle: "solid"  //线样式
          });

        }
      },
      runMaker(AMap){
        setTimeout(() => {
          let makerList=[];
          this.runMakerList.forEach((obj,index)=>{
            //console.log("runMaker:"+this.runMakerList.length+";"+obj.num);
            if(obj.maker!=null){
              this.map.remove(obj.maker);
            }
            let maker=this.getRunMaker(AMap,obj.workerNames,obj.recordList[obj.num]);
            obj.maker=maker;
            makerList.push(maker);
            obj.num+=1;
            if(obj.num>=obj.recordList.length){
              obj.num=0;
            }
            this.runMakerList.splice(index,1, obj);
          })
          this.map.add(makerList);
          this.runMaker(AMap);
        }, 2000)
      },
      getRunMaker(AMap,workerName,obj){
        //设置maker
        let title="巡逻人:"+workerName;
        if(obj.recordDateTime){
          title+="<br/>时间:"+obj.recordDateTime;
        }
        title="<div style='padding: 10px;font-size: 22px;border-radius: 10px;background-color: #008080;color: #ffffff;line-height: 28px;'>"+title+"</div>"
        let makerPoint= new AMap.Marker({
          position: [obj.longitude, obj.latitude],
          icon: new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(60, 69),
            // 图标的取图地址
            image: 'https://zhab.oss-cn-beijing.aliyuncs.com/diy/diyGrid/202503051154062025030511540600890827879.png',
            // 图标所用图片大小
            imageSize: new AMap.Size(60, 69),
          }),
          offset: new AMap.Pixel(0,0), //设置偏移量
          label: {
            direction: 'top',
            content: title,
            offset: new AMap.Pixel(0, -5),
          }
        });

        return makerPoint;
      },

      poiPickerReady(poiPicker,map,AMap) {
        

        window.poiPicker = poiPicker;

        var marker = new AMap.Marker();

        var infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -20)
        });

        //选取了某个POI
        poiPicker.on('poiPicked', function(poiResult) {


            var source = poiResult.source,
                poi = poiResult.item,
                info = {
                    source: source,
                    id: poi.id,
                    name: poi.name,
                    location: poi.location.toString(),
                    address: poi.address
                };

            marker.setMap(map);
            infoWindow.setMap(map);

            marker.setPosition(poi.location);
            infoWindow.setPosition(poi.location);

            infoWindow.setContent('POI信息: <pre>' + JSON.stringify(info, null, 2) + '</pre>');
            infoWindow.open(map, marker.getPosition());

            map.setCenter(marker.getPosition());
        });

        poiPicker.onCityReady(function() {
            poiPicker.suggest('美食');
        });
        },
        
        handleClose() {
        
      },
      callBtn(){
        this.dialogVisible=false
      }
        


		}
	}
</script>

<style lang="scss">

	.baseBody{
		width:100%;height: 100vh;overflow: hidden;position:relative;
		font-size:14px;
		background:#000208;
		position:relative;
		.main-wraper{
			user-select: none;
			width:1920px;height:1080px;
			margin:0 auto;
			background: #02081a url('../assets/images/sytBg2.png') center no-repeat;
			background-size: cover;
			box-sizing: border-box;
		}
	
	}
	.dateWeek{position:absolute;top:0;left:0;width:200px;height:50px;color:#ffffff; z-index: 200;}
	.fullBox{position:absolute;top:0;right:0;width:200px;height:50px;color:#ffffff;z-index: 999;}
	.nyBaseHeadTop {
		width: 100%;
		height: 80px;
		background: url('../assets/images/titleBg.png') top center no-repeat;
		background-size: auto 80px
	}

	.base-headTit {
		font-size: 30px;
		font-weight: bold;
		box-sizing: border-box;
		padding-top: 24px;
	}

	.baseTitleLeft {
		width: 644px;
		height: 55px;
		background: url('../assets/images/titleLeft.png') top right no-repeat;
		background-size: auto 55px
	}

	.baseTitleRight {
		width: 644px;
		height: 55px;
		background: url('../assets/images/titleRight.png') top left no-repeat;
		background-size: auto 55px
	}

	.baseBody .baseTitleContent {
		width: 100%;
		height: 100px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
    z-index: 200;
	}

	.baseBody .baseTitleContent .baseTitleMid {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../assets/images/headtitileBg3.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .top {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../assets/images/headtitileBg2.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .topLine {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../assets/images/titleBg4.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .title {
		width: 402px;
		height: 70px;
		margin: 0 auto;
		font-size: 34px;
		font-weight: bold;
		line-height: 70px;
		background: url('../assets/images/headtitileBg1.png') top left no-repeat;
		background-size: auto 70px
	}
  .baseCallAlarm{height:45px;line-height:45px;background:#FFFFFF;border-radius:7px;position:fixed;right:50px;top:10vh;z-index:999999;padding:0 20px;
    .bjImg{width:30px;height:30px;
        .el-image{width:30px;height:30px;}
       
    }
    .title{color:#4083f2;font-size:20px;font-weight:bold;margin-left:10px;}
    &:hover{cursor: pointer;box-shadow:0 0 20px rgba(64,131,242, 0.7);}
  }
 
  .basePoiBox{
    position:fixed;left:50px;top:10vh;z-index:999999;height:45px;line-height:45px;background:#FFFFFF;border-radius:7px;padding:0 5px 0 0;overflow: hidden;
    .relative{position: relative;
      input{height:44px;width:250px;margin:0 10px;border:none;vertical-align:top;font-size:16px;line-height:45px;padding:0 0 0 40px;}
		  i.el-icon-search{font-size:20px;color: #7c8196;font-weight:bold;cursor: pointer;}
      .poiIcon{width:20px;height:30px;position:absolute;left:12px;top:7px;
        .el-image{width:20px;height:30px}
      }
    }
		
	}

</style>
<style>
.amap-sug-result{
  .auto-item{font-size:14px;padding:10px;}
}
.dialogCustom{background:#052f61;border-radius:12px;}
.dialogCustom .el-dialog__header{display: none;}
.dialogCustom .el-form-item__label{color:#FFFFFF;}
.dialogCustom .dialogBox{padding-top: 30px;}
.dialogCustom .dialogBox .el-textarea__inner,.dialogCustom .dialogBox .el-input__inner{background: #164677;border:none;color:#FFFFFF;}
.base-font-16{font-size: 16px;}
.base-font-17{font-size: 17px;}
.base-font-18{font-size: 18px;}
.base-font-20{font-size: 20px;}
.dialogBtn{width:105px;height:40px;border-radius:3px;line-height:40px;font-weight:bold;font-size:15px;margin:0 15px;cursor: pointer;;}
.dialogBtn.qx{border:1px solid #3e76d2;color:#3e76d2;}
.dialogBtn.qd{border:1px solid #fffdda;background: #fffdda;color:#032f61;}
</style>
